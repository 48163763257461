<template>
  <div class="main-card mb-3 card pa-0 ma-0 transparent">
    <div class="card-body pa-0">
      <div
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
        class="card-title card-header-tab card-header"
      >
        <div
          class="card-header-title font-size-lg text-capitalize font-weight-normal"
        >
          {{ this.$t('generic.lang_zahlungsoptionen') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <div class="btn-actions-pane-right actions-icon-btn">
            <v-btn
              :disabled="loading || this.loadData"
              depressed
              :loading="loading"
              @click="update"
              class="elevation-0"
              color="success"
            >
              {{ this.$t('generic.lang_save') }}
            </v-btn>
          </div>
        </div>
      </div>

      <v-row class="py-0">
        <v-col
          class="pb-0"
          cols="12"
        >
          <v-card class="pa-0">
            <v-data-table
              :headers="dataTableHeader"
              :items="payments"
              :loading="loading || this.loadData"
              :items-per-page="-1"
            >
              <template v-slot:item.status="{ item }">
                <v-checkbox v-model="item.active"> </v-checkbox>
              </template>

              <template v-slot:item.crudEdit="{ item }">
                <v-btn
                  elevation="0"
                  color="primary"
                  icon
                  @click="editEntry(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialogEdit"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{
              this.$t('tablebee.lang_edit_payment_method_translation')
            }}</span>
          </v-card-title>

          <v-card-text class="mt-3">
            <v-form
              v-model="valid"
              ref="payementType"
              lazy-validation
              style="width: 100%"
            >
              <b-tabs>
                <b-tab active>
                  <template #title>
                    <v-btn
                      text
                      class="ma-0"
                    >
                      <country-flag country="DE" />
                    </v-btn>
                  </template>

                  <v-row no-gutters>
                    <v-col
                      class=""
                      cols="12"
                    >
                      <v-subheader>
                        <strong>
                          {{
                            this.$t('accounting.lang_payment_method_') +
                            `: ${this.slectedPayement.paymentName}`
                          }}
                        </strong>
                      </v-subheader>
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        flat
                        v-model="paymentType_translations.de"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </b-tab>

                <b-tab>
                  <template #title>
                    <v-btn
                      text
                      class="ma-0"
                    >
                      <country-flag country="GB" />
                    </v-btn>
                  </template>

                  <v-row no-gutters>
                    <v-col
                      class=""
                      cols="12"
                    >
                      <v-subheader>
                        <strong>
                          {{
                            this.$t('accounting.lang_payment_method_') +
                            `: ${this.slectedPayement.paymentName}`
                          }}
                        </strong>
                      </v-subheader>
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        flat
                        v-model="paymentType_translations.en"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </b-tab>

                <b-tab>
                  <template #title>
                    <v-btn
                      text
                      class="ma-0"
                    >
                      <country-flag country="FR" />
                    </v-btn>
                  </template>

                  <v-row no-gutters>
                    <v-col
                      class=""
                      cols="12"
                    >
                      <v-subheader>
                        <strong>
                          {{
                            this.$t('accounting.lang_payment_method_') +
                            `: ${this.slectedPayement.paymentName}`
                          }}
                        </strong>
                      </v-subheader>
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        flat
                        v-model="paymentType_translations.fr"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </b-tab>

                <b-tab>
                  <template #title>
                    <v-btn
                      text
                      class="ma-0"
                    >
                      <country-flag country="SA" />
                    </v-btn>
                  </template>

                  <v-row no-gutters>
                    <v-col
                      class=""
                      cols="12"
                    >
                      <v-subheader>
                        <strong>
                          {{
                            this.$t('accounting.lang_payment_method_') +
                            `: ${this.slectedPayement.paymentName}`
                          }}
                        </strong>
                      </v-subheader>
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        flat
                        v-model="paymentType_translations.ar"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </b-tab>
              </b-tabs>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="cancelEditDialog()"
            >
              {{ $t('generic.lang_cancel') }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!valid"
              text
              @click="editPayementType()"
            >
              {{ $t('generic.lang_edit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import CountryFlag from 'vue-country-flag';

export default {
  name: 'EventbeePaymentOptionComponent',
  components: {
    CountryFlag,
  },
  data() {
    return {
      loading: false,
      valid: false,
      dialogEdit: false,
      slectedPayement: {},
      paymentType_translations: { de: '', en: '', fr: '', ar: '' },
      payments: [],
      loadData: false,
      paypalID: null,
      paypalSecret: null,
      dataTableHeader: [
        {
          text: this.$t('accounting.lang_payment_id'),
          hide: true,
          value: 'id',
          width: 100,
        },
        {
          text: this.$t('accounting.lang_payment_method_'),
          value: 'paymentName',
        },
        {
          text: this.$t('settings.lang_settings_activ'),
          value: 'status',
        },
        {
          text: '',
          value: 'crudEdit',
          align: 'center',
          width: 100,
        },
      ],
    };
  },
  methods: {
    update() {
      let data = [];
      if (Array.isArray(this.payments))
        data = this.payments.map(({ id, active }) => ({
          id: id,
          is_active: active === true,
        }));

      this.loading = true;

      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.PAYMENTS.BULKACTIVE, {
          payments: data,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_dataSaved'),
              color: 'success',
            });
          } else {
            Events.$emit('showSnackbar', {
              message: res.data.msg || res.data.status,
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally((fin) => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.PAYMENTS.GET)
        .then((res) => {
          this.payments = res.data.formfillData.textFields;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editEntry(entry) {
      this.dialogEdit = true;
      this.slectedPayement = entry;
      this.getDynamicContent();
    },
    cancelEditDialog() {
      this.dialogEdit = false;
      this.$refs.payementType.reset();
    },
    getDynamicContent() {
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.PAYMENTS.GET)
        .then((res) => {
          if (res.status === 200) {
            res.data.formfillData.textFields.forEach((element) => {
              if (element['id'] == this.slectedPayement.id) {
                const lastKey = Object.keys(element).pop();

                this.paymentType_translations.de = element[lastKey].de;
                this.paymentType_translations.en = element[lastKey].en;
                this.paymentType_translations.fr = element[lastKey].fr;
                this.paymentType_translations.ar = element[lastKey].ar;
              }
            });
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
            color: 'error',
          });
        });
    },
    editPayementType() {
      let data = {};
      if (this.slectedPayement.id == 'eventbee_paypal_active') {
        data = {
          eventbee_paypal_translations: this.paymentType_translations,
          eventbee_pay_at_checkout_translations: null,
          eventbee_secupay_translations: null,
          eventbee_stripe_translations: null,
        };
      } else if (this.slectedPayement.id == 'eventbee_pay_at_checkout_active') {
        data = {
          eventbee_paypal_translations: null,
          eventbee_pay_at_checkout_translations: this.paymentType_translations,
          eventbee_secupay_translations: null,
          eventbee_stripe_translations: null,
        };
      } else if (this.slectedPayement.id == 'eventbee_secupay_active') {
        data = {
          eventbee_paypal_translations: null,
          eventbee_pay_at_checkout_translations: null,
          eventbee_secupay_translations: this.paymentType_translations,
          eventbee_stripe_translations: null,
        };
      } else if (this.slectedPayement.id == 'eventbee_stripe_active') {
        data = {
          eventbee_paypal_translations: null,
          eventbee_pay_at_checkout_translations: null,
          eventbee_secupay_translations: null,
          eventbee_stripe_translations: this.paymentType_translations,
        };
      }

      this.axios
        .post(
          ENDPOINTS.EVENTBEE.SETTINGS.PAYMENTS.PAYMENT_OPTIONS_TRANSLATIONS,
          data
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 'success') {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_actionSuccessful'),
              color: 'success',
            });

            this.dialogEdit = false;
            this.$refs.payementType.reset();
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
            color: 'error',
          });
        });
    },
  },
  mounted() {
    this.getData();
  },
  computed: {},
};
</script>

<style scoped></style>
