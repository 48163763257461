<template>
  <v-container class="card pa-0" fluid>
    <v-card class="card-body ma-0 pa-0" elevation="0">
      <v-card-title
        class="card-header card-header-tab bg-white border-0 transparent"
      >
        <div
          :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
          class="card-title text-capitalize pt-3"
        >
          {{ $t("generic.lang_ticketCodes") }}
        </div>

        <div class="btn-actions-pane-right actions-icon-btn">
          <v-btn @click="dialog = true" color="primary" icon>
            <v-icon>add</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-divider class="pa-0 ma-0" />

      <v-card-text class="pa-0 ma-0">
        <Datatable
          :api-endpoint="ENDPOINTS.DATATABLES.EVENTBEE.TICKET_GENERATOR"
          :datatable-headers="datatableHeaders"
          :show-select-checkbox="false"
          no-excel-export
          ref="ticketUuids"
        >
        </Datatable>
      </v-card-text>
    </v-card>

    <v-dialog max-width="500" persistent v-model="dialog">
      <div
        :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''"
        class="card"
      >
        <div
          :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          class="card-header pt-3"
        >
          <div
            :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
            class="card-title"
          >
            {{
              this.$t("generic.lang_create") +
              " " +
              this.$t("generic.lang_ticketCodes")
            }}
          </div>
        </div>

        <div class="card-body">
          <v-form lazy-validation v-model="valid" ref="ticketCodesForm">
            <p>{{ $t("eventbee.lang_createTicketCodesMSG") }}</p>
            <br />

            <v-text-field
              v-model="ticketCodesQty"
              type="number"
              :label="$t('erp.lang_createGiftcardCodesLabel')"
              outlined
              autocomplete="off"
              required
              @focus="showTouchKeyboard"
              :data-layout="KEYBOARD.KEYSETS.NORMAL"
            ></v-text-field>
          </v-form>
        </div>

        <div
          :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          class="card-footer text-right"
        >
          <v-spacer />
          <v-btn
          :disabled="loading"
            @click="dialog = false"
            class="btn btn-danger bg-danger text-light mx-auto"
          >
            {{ this.$t("generic.lang_cancel") }}
          </v-btn>
          <v-btn
            :disabled="!valid || loading"
            :loading="loading"
            @click="downloadTicketCodes"
            class="btn bg-primary text-light btn-primary mx-auto ml-2"
          >
            {{ this.$t("generic.lang_create") }}
          </v-btn>
        </div>
      </div>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard
          v-if="touchKeyboard.visible"
          id="onScreenKeyboard"
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
        />
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import { mapState } from "vuex";
import Datatable from "@/components/datatable/Datatable";

export default {
  name: "PredefinedTicketCodesComponent",
  components: {
    Datatable,
  },
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      dialog: false,
      valid: false,
      loading: false,
      ticketCodesQty: 0,
    };
  },
  computed: {
    ...mapState(["api"]),

    datatableHeaders: function () {
      return [
        {
          text: this.$t("generic.lang_ticketUuid"),
          align: "left",
          value: "id",
        },
        {
          text: this.$t("generic.lang_soldDate"),
          align: "left",
          value: "soldDate",
        },
      ];
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.ticketCodesQty = 0;
    },
    downloadTicketCodes() {
      this.loading = true;

      let apiServerIP = process.env.VUE_APP_API_HOST;
      if (
        window.location.host != "localhost" &&
        window.location.host != "devkasse.3pos.de" &&
        window.location.host != "kasse.3pos.de"
      ) {
        let apiServerIP =
          location.protocol + "//" + window.location.hostname + ":8010/pos";
      }

      fetch(
        `${apiServerIP}/create/eventBee/ticketCodeGenerator/?qty=${this.ticketCodesQty}&bs=${this.api.auth.posID}&bsid=${this.api.auth.accessString}&apiToken=${this.api.auth.apiWebToken}`
      )
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "ticketCodes.xlsx";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove element

          this.close();
          this.$refs.ticketUuids.getDataFromApi();
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
