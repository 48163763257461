<template>
    <v-container class="card pa-0" fluid>
        <v-card class="card-body ma-0 pa-0" elevation="0">
            <v-card-title class="card-header card-header-tab bg-white border-0 transparent">
                <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title text-capitalize pt-3">
                    {{ $t('eventbee.lang_priceSegments') }}
                </div>

                <div class="btn-actions-pane-right actions-icon-btn">
                    <v-btn @click="dialog = true" color="primary" icon>
                        <v-icon>add</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <div class="px-2">
                <v-alert
                    width="100%"
                    outlined
                    type="info"
                >
                    {{ $t("eventbee.lang_priceSegmentsDescriptionInfo") }}
                </v-alert>
            </div>
            <v-divider class="pa-0 ma-0"/>
            <v-card-text class="pa-0 ma-0">
                <Datatable
                        :api-endpoint="ENDPOINTS.DATATABLES.EVENTBEE.PRICESEGMENTS"
                        :datatable-headers="datatableHeaders"
                        :permissionDelete="true"
                        :permissionEdit="true"
                        @deleteEntries="deleteEntries"
                        @deleteEntry="deleteEntry"
                        @editEntry="editEntry"
                        no-excel-export
                        ref="segments"
                        show-delete-buttons
                        show-edit-buttons
                        show-select-checkbox
                ></Datatable>
            </v-card-text>
        </v-card>

        <v-dialog max-width="500" persistent v-model="dialog">
            <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="card">
                <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header pt-3">
                    <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title">{{
                        this.formTitle
                        }}
                    </div>
                </div>

                <div class="card-body">
                    <v-form lazy-validation v-model="valid" ref="priceSegmentForm">
                        <v-text-field v-model="form.name" outlined dense :rules="[v=>!!v]"
                                      :label="$t('generic.lang_name')"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      @focus="showTouchKeyboard">

                        </v-text-field>

                        <v-text-field v-model="form.kontingent" type="number" :min="0" outlined dense
                                      :label="$t('eventbee.lang_quota')"
                                      :rules="[v=>!!v]"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      @focus="showTouchKeyboard">

                        </v-text-field>

                        <v-select v-model="form.taxID" outlined dense :label="$t('erp.lang_tax')" item-value="id"
                                  item-text="name"
                                  :rules="[v=>!!v]" :items="taxes">

                        </v-select>

                        <v-card flat>
                            <v-card-title>
                                <span class="subtitle-2">{{ $t("generic.lang_description") }}</span>
                            </v-card-title>

                            <v-card-text>
                                <b-tabs>
                                <b-tab active>
                                    <template #title>
                                    <v-btn text class="ma-0">
                                        <country-flag country="DE" />
                                    </v-btn>
                                    </template>

                                    <v-row no-gutters>
                                    <v-col class="" cols="12">
                                        <v-text-field
                                        hide-details
                                        outlined
                                        dense
                                        flat
                                        v-model="descriptionObj.de"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    </v-row>
                                </b-tab>

                                <b-tab>
                                    <template #title>
                                    <v-btn text class="ma-0">
                                        <country-flag country="GB" />
                                    </v-btn>
                                    </template>

                                    <v-row no-gutters>
                                    <v-col class="" cols="12">
                                        <v-text-field
                                        hide-details
                                        outlined
                                        dense
                                        flat
                                        v-model="descriptionObj.en"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    </v-row>
                                </b-tab>

                                <b-tab>
                                    <template #title>
                                    <v-btn text class="ma-0">
                                        <country-flag country="FR" />
                                    </v-btn>
                                    </template>

                                    <v-row no-gutters>
                                    <v-col class="" cols="12">
                                        <v-text-field
                                        hide-details
                                        outlined
                                        dense
                                        flat
                                        v-model="descriptionObj.fr"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    </v-row>
                                </b-tab>

                                <b-tab>
                                    <template #title>
                                    <v-btn text class="ma-0">
                                        <country-flag country="SA" />
                                    </v-btn>
                                    </template>

                                    <v-row no-gutters>
                                    <v-col class="" cols="12">
                                        <v-text-field
                                        hide-details
                                        outlined
                                        dense
                                        flat
                                        v-model="descriptionObj.ar"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    </v-row>
                                </b-tab>
                                </b-tabs>
                            </v-card-text>
                            </v-card>

                        <v-checkbox
                                v-model="form.dont_issue_ticket"
                                :label="$t('eventbee.lang_doNotIssueTicket')"
                                :true-value="'1'" :false-value="'0'"
                        ></v-checkbox>

                        <v-checkbox
                                v-model="form.issue_ticket_foreach_kontingent"
                                :label="$t('eventbee.lang_issueForEachKontingent')"
                                :true-value="'1'" :false-value="'0'"
                        ></v-checkbox>

                        <v-checkbox
                                v-model="form.enablePriceSegmentsForKiosk"
                                :label="$t('eventbee.lang_enablePriceSegmentsForKiosk')"
                                :true-value="'1'" :false-value="'0'"
                        ></v-checkbox>
                    </v-form>
                </div>
                <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right">
                    <v-spacer/>
                    <v-btn @click="dialog = false" class="btn btn-danger bg-danger text-light mx-auto">
                        {{ this.$t('generic.lang_cancel') }}
                    </v-btn>
                    <v-btn :disabled="!valid||loading" :loading="loading" @click="save"
                           class="btn bg-primary text-light btn-primary mx-auto ml-2">
                        {{ this.edit ? this.$t('generic.lang_save') : this.$t('generic.lang_create') }}
                    </v-btn>
                </div>
            </div>
            <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                                    :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                                    :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                    :options="touchKeyboard.options" class="internalWidthExpanded"/>
            </div>
        </v-dialog>
    </v-container>
</template>

<script>

import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from '@/config';
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns"
import CountryFlag from "vue-country-flag";

export default {
    name: "PriceSegmentsComponent",
    components: {
        Datatable,
        CountryFlag
    },
    mixins: [mixin],
    data: () => {
        return {
            ENDPOINTS,
            dialog: false,
            valid: false,
            form: {
                id: 0,
                name: null,
                kontingent: null,
                taxID: 0,
                dont_issue_ticket: '0',
                issue_ticket_foreach_kontingent: '0',
                enablePriceSegmentsForKiosk: '1',
                descriptionObj: { de: "", en: "", fr: "", ar: "" },
            },
            defaultItem: {
                id: 0,
                name: null,
                kontingent: null,
                taxID: 0,
                dont_issue_ticket: '0',
                issue_ticket_foreach_kontingent: '0',
                enablePriceSegmentsForKiosk: '1',
                descriptionObj: { de: "", en: "", fr: "", ar: "" },
            },
            descriptionObj: { de: "", en: "", fr: "", ar: "" },
            loading: false,
            edit: false,
            taxes: [],
        }
    },
    watch: {
        dialog(val) {
            val || val & this.close();
            this.form.taxID = Number(this.form.taxID)
        },
    },
    methods: {
        deleteData(idsToDelete = []) {
            let self = this;
            this.$swal({
                title: this.$t('generic.lang_delete') + ' ' + this.$t('eventbee.lang_priceSegment'),
                text: this.$t('generic.lang_deleteConfirmationMSG') + ' ' + this.$t('eventbee.lang_priceSegment'),
                icon: "warning",
                cancelButtonText: this.$t('generic.lang_cancel'),
                confirmButtonText: this.$t('generic.lang_delete'),
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                        idsToDelete = [];
                    }
                    this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.PRICESEGMENTS.DELETE, {
                        id: idsToDelete,
                    }).then((res) => {
                        if (res.status === 200) {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_success'),
                                color: "success"
                            });
                            self.$refs.segments.getDataFromApi();
                            self.$refs.segments.resetSelectedRows();
                        } else {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_errorOccurred'),
                                color: "error"
                            });
                        }
                    });
                },
                allowOutsideClick: () => !this.$swal.isLoading,
            });
        },
        deleteEntry(entry) {
            this.deleteData([entry.id]);
        },
        deleteEntries(entries) {
            this.deleteData(entries);
        },
        save() {
            if (!this.$refs.priceSegmentForm.validate()) {
                return
            }

            this.form.descriptionObj = this.descriptionObj;

            if (this.edit) {
                this.loading = true;
                this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.PRICESEGMENTS.UPDATE, this.form).then((res) => {
                    if (res.data.status === "SUCCESS") {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_success'),
                            color: "success"
                        });
                        this.close()
                        this.$refs.segments.getDataFromApi();
                        this.$refs.segments.resetSelectedRows();
                    } else if (res.data.status === "FAILED") {
                        Events.$emit("showSnackbar", {
                            message: res.data.msg,
                            color: "error"
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                    this.dialog = false;
                });
            } else {
                this.loading = true;
                this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.PRICESEGMENTS.CREATE, this.form).then((res) => {
                    if (res.data.STATUS) {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_success'),
                            color: "success"
                        });
                        this.close()
                        this.$refs.segments.getDataFromApi();
                        this.$refs.segments.resetSelectedRows();
                    } else if (res.data.status === "FAILED") {
                        Events.$emit("showSnackbar", {
                            message: res.data.msg,
                            color: "error"
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                    this.form = Object.assign({}, this.defaultItem);
                });
            }
        },
        getTaxes() {
            this.axios.post(ENDPOINTS.ERP.TAXSETUP.GETALL, this.form).then((res) => {
                if (res.data.STATUS === 'SUCCESS') {
                    this.taxes = res.data.tax;
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                    message: err.message,
                    color: "error"
                });
            }).finally(() => {
                this.loading = false;
            });

        },
        editEntry(entry) {
            this.edit = true;
            this.dialog = true;
            this.form = Object.assign({}, entry);
            if (this.form.descriptionObj) {
              this.descriptionObj = JSON.parse(this.form.descriptionObj);  
            }  
        },
        close() {
            this.edit = false;
            this.dialog = false;
            this.form = Object.assign({}, this.defaultItem);
            // if (this.$refs.priceSegmentForm) this.$refs.priceSegmentForm.reset();
        }
    },
    computed: {
        formTitle: function () {
            return this.edit ? this.$t('generic.lang_edit') + ' ' + this.$t('eventbee.lang_priceSegment') : this.$t('generic.lang_create') + ' ' + this.$t('eventbee.lang_priceSegment');
        },
        datatableHeaders: function () {
            return [
                {
                    text: 'ID',
                    align: 'left',
                    value: 'id',
                    width: 80,
                    hide: false,
                },
                {
                    text: this.$t('generic.lang_name'),
                    value: 'name',
                    sort: "asc"
                },
                {
                    text: this.$t('generic.lang_cashier_price'),
                    align: 'left',
                    value: 'price',
                    width: 80,
                    hide: true,
                },
                {
                    text: this.$t('eventbee.lang_quota'),
                    align: 'left',
                    value: 'kontingent',
                    width: 80,
                },
                {
                    text: this.$t('erp.lang_tax'),
                    align: 'left',
                    value: 'taxID',
                    width: 80,
                    hide: true,
                },
                {
                    text: this.$t('eventbee.lang_doNotIssueTicket'),
                    align: 'left',
                    value: 'dont_issue_ticket',
                    hide: true,
                },
                {
                    text: this.$t('eventbee.lang_issueForEachKontingent'),
                    align: 'left',
                    value: 'issue_ticket_foreach_kontingent',
                    hide: true,
                },
                {
                    text: this.$t('eventbee.lang_enablePriceSegmentsForKiosk'),
                    align: 'left',
                    value: 'enablePriceSegmentsForKiosk',
                    hide: true,
                },
                {
                    text: this.$t('generic.lang_description'),
                    align: 'left',
                    value: 'descriptionObj',
                    hide: true,
                }
            ]
        }
    },
    mounted() {
        this.getTaxes();
    }
}
</script>

<style scoped>

</style>
