<template>
  <div>
    <page-title :heading="'EventBee'" :icon=icon :subheading="$t('settings.lang_settings')"
    ></page-title>
    <div class="app-main__inner">
      <EventbeeSettingsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import EventbeeSettingsComponent from "../../../components/eventbee/settings/EventbeeSettingsComponent";
export default {
  name: "EventbeeSettings",
  components: {EventbeeSettingsComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-config icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>