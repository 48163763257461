import { render, staticRenderFns } from "./GeneralInfoComponent.vue?vue&type=template&id=735a6fb0&scoped=true"
import script from "./GeneralInfoComponent.vue?vue&type=script&lang=js"
export * from "./GeneralInfoComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "735a6fb0",
  null
  
)

export default component.exports