var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',{attrs:{"role":"tablist"}},[_c('b-card',{class:[this.$vuetify.theme.dark ? 'dark-bg' : '', 'mb-1'],attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('v-list-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.settings",modifiers:{"settings":true}}],staticClass:"ma-0",on:{"click":function($event){_vm.globalSettings = !_vm.globalSettings}}},[_c('v-list-item-title',{class:this.globalSettings
                        ? 'primary--text text-wrap'
                        : 'text-wrap'},[_vm._v(" "+_vm._s('EventBee ' + _vm.$t('settings.lang_settings'))+" ")]),_c('v-list-item-action',[(!this.globalSettings)?_c('v-icon',[_vm._v("keyboard_arrow_down")]):_vm._e(),(this.globalSettings)?_c('v-icon',[_vm._v("keyboard_arrow_up")]):_vm._e()],1)],1)],1),_c('b-collapse',{attrs:{"visible":this.globalSettings,"accordion":"settings","id":"settings","role":"tabpanel"}},[_c('b-card-body',{staticClass:"pa-0"},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'generalInfo'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('generalInfo')}}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_globalInformations'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'eventGroup'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('eventGroup')}}},[_vm._v(" "+_vm._s(_vm.$t('eventbee.lang_eventGroups'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'seatingmaps'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('seatingmaps')}}},[_vm._v(" "+_vm._s(_vm.$t('eventbee.lang_seatingMaps'))+" ")])],1)],1)],1)],1)],1)]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',{attrs:{"role":"tablist"}},[_c('b-card',{class:[this.$vuetify.theme.dark ? 'dark-bg' : '', 'mb-1'],attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('v-list-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.preSettings",modifiers:{"preSettings":true}}],staticClass:"ma-0",on:{"click":function($event){_vm.globalSettings = !_vm.globalSettings}}},[_c('v-list-item-title',{class:this.globalSettings
                        ? 'primary--text text-wrap'
                        : 'text-wrap'},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_otherSettings'))+" ")]),_c('v-list-item-action',[(!this.globalSettings)?_c('v-icon',[_vm._v("keyboard_arrow_down")]):_vm._e(),(this.globalSettings)?_c('v-icon',[_vm._v("keyboard_arrow_up")]):_vm._e()],1)],1)],1),_c('b-collapse',{attrs:{"visible":this.globalSettings,"accordion":"preSettings","id":"preSettings","role":"tabpanel"}},[_c('b-card-body',{staticClass:"pa-0"},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'priceSegments'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('priceSegments')}}},[_vm._v(" "+_vm._s(_vm.$t('eventbee.lang_priceSegments'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'priceCategories'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('priceCategories')}}},[_vm._v(" "+_vm._s(_vm.$t('eventbee.lang_seatCategories'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'attributes'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('attributes')}}},[_vm._v(" "+_vm._s(_vm.$t('eventbee.lang_attributeGroups'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'pools'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('pools')}}},[_vm._v(" "+_vm._s(_vm.$t('eventbee.lang_pools'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:this.currentTab === 'payment' ? 'primary--text' : '',on:{"click":function($event){return _vm.handleClick('payment')}}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_zahlungsoptionen'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'customerNotifications'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('customerNotifications')}}},[_vm._v(" "+_vm._s(_vm.$t('eventbee.lang_customerNotifications'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'emailConfig'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('emailConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('settings.lang_emailSettings'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'emailTemplates'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('emailTemplates')}}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_answerEmailTemplates'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'refundPolicies'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('refundPolicies')}}},[_vm._v(" "+_vm._s(_vm.$t('eventbee.lang_refundPolicies'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'dynamicContent'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('dynamicContent')}}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_dynamicContent'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'predefinedTicket'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('predefinedTicket')}}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_ticketCodes'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'globalSettings'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('globalSettings')}}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_globalSettings'))+" ")])],1)],1)],1)],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9","sm":"12"}},[(_vm.currentTab === 'generalInfo')?_c('div',[_c('GeneralInfoComponent')],1):_vm._e(),(_vm.currentTab === 'times')?_c('div'):_vm._e(),(_vm.currentTab === 'seatingmaps')?_c('div',[_c('SeatingMapsComponent')],1):_vm._e(),(_vm.currentTab === 'design')?_c('div'):_vm._e(),(_vm.currentTab === 'payment')?_c('div'):_vm._e(),(_vm.currentTab === 'eventGroup')?_c('div',[_c('EventGroupsComponent')],1):_vm._e(),(_vm.currentTab === 'products')?_c('div'):_vm._e(),(_vm.currentTab === 'settings')?_c('div'):_vm._e(),(_vm.currentTab === 'preview')?_c('div'):_vm._e(),(_vm.currentTab === 'help')?_c('div'):_vm._e(),(_vm.currentTab === 'priceSegments')?_c('div',[_c('PriceSegmentsComponent')],1):_vm._e(),(_vm.currentTab === 'priceCategories')?_c('div',[_c('SeatCategoriesComponent')],1):_vm._e(),(_vm.currentTab === 'attributes')?_c('div',[_c('AttributeGroupsComponent')],1):_vm._e(),(_vm.currentTab === 'pools')?_c('div',[_c('EventbeePoolsComponent')],1):_vm._e(),(_vm.currentTab === 'payment')?_c('div',[_c('eventbee-payment-option-component')],1):_vm._e(),(_vm.currentTab === 'customerNotifications')?_c('div',[_c('CustomerNotificationsComponent')],1):_vm._e(),(_vm.currentTab === 'emailConfig')?_c('div',[_c('EventbeeEmailConfigComponent')],1):_vm._e(),(_vm.currentTab === 'emailTemplates')?_c('div',[_c('EmailTemplatesComponent')],1):_vm._e(),(_vm.currentTab === 'refundPolicies')?_c('div',[_c('RefundPoliciesComponent')],1):_vm._e(),(_vm.currentTab === 'dynamicContent')?_c('div',[_c('PrivacyAGBConditionsComponent')],1):_vm._e(),(_vm.currentTab === 'predefinedTicket')?_c('div',[_c('PredefinedTicketCodesComponent')],1):_vm._e(),(_vm.currentTab === 'globalSettings')?_c('div',[_c('EventbeeGlobalSettingsComponent')],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }