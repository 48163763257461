<template>
  <b-card no-body class="mb-1 transparent">
    <v-toolbar elevation="0" max-height="50" class="transparent">
      <v-tabs v-model="tab" height="50">
        <v-tab>
          {{ $t("generic.lang_sms") }}
        </v-tab>
        <v-tab>
          {{ $t("generic.lang_email") }}
        </v-tab>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="success"
          @click="update"
          :disabled="loading"
          :loading="loading"
        >
          {{ $t("generic.lang_save") }}
        </v-btn>
      </v-tabs>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item class="px-5 pt-5">
        <v-form ref="smsForm" v-model="validSms">
          <v-checkbox
            :label="$t('generic.lang_enableSmsNotificationAbility')"
            v-model="eb_smsNotificationActive"
          />

          <v-alert
            v-if="!$store.getters['permissions/checkModule'](70)"
            width="100%"
            outlined
            type="info"
          >
            {{ $t("generic.lang_smsGatewayNotActive") }}
          </v-alert>
          <v-textarea
            v-else
            rows="4"
            outlined
            dense
            class="my-3"
            :label="$t('generic.lang_notificationText')"
            :rules="[rules.maxChars(eb_smsNotificationText, 150)]"
            @keypress="maxChars($event)"
            v-model="eb_smsNotificationText"
          />
          <v-text-field
            type="number"
            v-model="eb_smsNotificationAdvanceTime"
            :rules="[(v) => (v && v >= 0) || $t('generic.lang_mustBeGreater0')]"
            :min="0"
            dense
            outlined
            :label="$t('generic.lang_notificationAdvanceTimeLabel')"
          >
            <template v-slot:append>
              <span class="mt-1">{{ $t("generic.lang_minutes") }}</span>
            </template>
          </v-text-field>
        </v-form>
      </v-tab-item>

      <v-tab-item class="px-5 pt-5">
        <v-form ref="emailForm" v-model="validEmail">
          <v-checkbox
            class="mb-3"
            :label="$t('generic.lang_enableEmailNotificationAbility')"
            v-model="eb_emailNotificationActive"
          />

          <v-text-field
            type="number"
            v-model="eb_emailNotificationAdvanceTime"
            :rules="[(v) => (v && v >= 0) || $t('generic.lang_mustBeGreater0')]"
            :min="0"
            dense
            outlined
            :label="$t('generic.lang_notificationAdvanceTimeLabel')"
          >
            <template v-slot:append>
              <span class="mt-1">{{ $t("generic.lang_minutes") }}</span>
            </template>
          </v-text-field>
          <v-select
            v-model="eb_emailNotificationId"
            :items="emailTemplates"
            item-text="tmplName"
            item-value="id"
            clearable
            outlined
            dense
            :placeholder="$t('erp.lang_selectedTemplate')"
            class="pt-5"
            :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
          ></v-select>
        </v-form>
      </v-tab-item>
    </v-tabs-items>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </b-card>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { isNumeric } from "mathjs";

export default {
  name: "CustomerNotificationsComponent",
  mixins: [mixin],

  data() {
    return {
      loading: false,
      tab: 0,
      validSms: false,
      validEmail: false,
      emailTemplates: [],
      eb_smsNotificationText: "",
      eb_smsNotificationAdvanceTime: 0,
      eb_emailNotificationAdvanceTime: 0,
      eb_emailNotificationId: 0,
      eb_smsNotificationActive: false,
      eb_emailNotificationActive: false,
      rules: {
        maxChars: (v, max) => v.length <= max || "Max " + max + " characters",
      },
    };
  },

  methods: {
    isNumeric,
    update() {
      if (this.tab === 0) {
        this.updateSms();
      } else if (this.tab === 1) {
        this.updateEmail();
      }
    },
    updateSms() {
      if (!this.$refs.smsForm.validate()) return;
      this.loading = true;

      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.CUSTOMER_NOTIFICATIONS.UPDATESMS, {
          eb_smsNotificationText: this.eb_smsNotificationText,
          eb_smsNotificationAdvanceTime: this.eb_smsNotificationAdvanceTime,
          eb_smsNotificationActive: this.eb_smsNotificationActive,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateEmail() {
      if (!this.$refs.emailForm.validate()) return;
      this.loading = true;

      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.CUSTOMER_NOTIFICATIONS.UPDATEEMAIL, {
          eb_emailNotificationAdvanceTime: this.eb_emailNotificationAdvanceTime,
          eb_emailNotificationId: this.eb_emailNotificationId,
          eb_emailNotificationActive: this.eb_emailNotificationActive,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getSms() {
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.CUSTOMER_NOTIFICATIONS.GETSMS)
        .then((res) => {
          if (res.status === 200) {
            this.eb_smsNotificationText = res.data.eb_smsNotificationText;
            this.eb_smsNotificationAdvanceTime = res.data.eb_smsNotificationAdvanceTime;
            this.eb_smsNotificationActive = res.data.eb_smsNotificationActive;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        });
    },
    getEmail() {
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.CUSTOMER_NOTIFICATIONS.GETEMAIL)
        .then((res) => {
          if (res.status === 200) {
            this.eb_emailNotificationAdvanceTime = res.data.eb_emailNotificationAdvanceTime;
            this.eb_emailNotificationId = res.data.eb_emailNotificationId;
            this.eb_emailNotificationActive = res.data.eb_emailNotificationActive;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        });
    },
    loadTemplates() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.EMAIL.TEMPLATES.GETALL)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.emailTemplates = res.data;
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    maxChars(evt) {
      if (this.eb_smsNotificationText.length === 150) {
        evt.preventDefault();
      }
    },
  },
  mounted() {
    this.getSms();
    this.getEmail();
    this.loadTemplates();
  },
};
</script>
